<template>
    <div class="content">
        <router-header :tabs="$tabs.promotionTabs" v-if="hasResource('promotion_management')"></router-header>
         <keep-alive  v-if="$route.meta.keepAlive">
            <router-view class="mg-b-45 mg-t-72"></router-view>
         </keep-alive>
        <router-view class="mg-b-45 mg-t-72" v-else></router-view>
    </div>
</template>
<script>
import routerHeader from '@/components/router-header';

export default {
    components: { 'RouterHeader': routerHeader},
}
</script>
